<template>

<v-card>
    <v-card-title>
       <span> Historial ralenti</span>

        <v-divider  class="mx-4" inset vertical></v-divider>

           <v-flex md2 lg2 mx-4 pt-5>
                 <v-select v-model="id_pais_activo" dense 
                 outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
           </v-flex>

            
                <v-btn dark color="success" @click="dialogInicio=true" class="mx-1">
                  <v-icon>date_range</v-icon>
                </v-btn>
           

            <vue-excel-xlsx v-show="data_excel.length >0"
              :data="data_excel"
              :columns="column_excel"
              :filename="'Historial_ralenti'"
              :sheetname="'hoja1'">
              <v-btn color="info">
                  <v-icon dark>get_app</v-icon>
              </v-btn>
            
              </vue-excel-xlsx>


                <v-spacer></v-spacer>
        
              <v-text-field
                v-model="search"
                append-icon="search"
                label="buscar"
                single-line
                hide-details
              ></v-text-field>






    </v-card-title>

  <v-data-table
    :headers="headers"
    :items="ArrayRalenti"
     sort-by="calories"
    :search="search"
    class="elevation-1"
  >
  
  
    <template v-slot:no-data>
      <v-btn  color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>

     <template v-slot:[`item.fecha`]="{ item }"> 
       <span>{{ConvertirFecha(item.fecha)}} </span>
     </template>


     

  </v-data-table>


    
<v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Ralenti por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>



           <v-flex>
      
                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
</v-dialog>

</v-card>

</template>


<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data: () => ({
        search:'',
      PaisesArray:[],
      id_pais_activo:'',
      dialog: false,
      dialogDelete: false,
      headers: [

        { text: 'Vehiculo', value: 'id_vehiculo.nombre' },
        { text: 'Placa', value: 'id_vehiculo.placa' },
        { text: 'Flota', value: 'id_vehiculo.flota' },

        { text: 'Distancia km', value: 'distancia' },
        { text: 'Hrs Ralenti', value: 'tiempo_parado_enc' },
        { text: 'Fecha', value: 'fecha' }

      ],
      ArrayRalenti: [],


        menu: false,
       dialogInicio:false,

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),


         data_excel:[],

          column_excel:[ 
                    {label: "Vehiculo",  field: "Vehiculo"},
                    {label: "Placa", field: "Placa"},
                    {label: "Flota", field: "Flota"},

                    {label: "Distancia",  field: "Distancia"},
                    {label: "Ralenti", field: "Ralenti"},
                    {label: "Fecha", field: "Fecha"}
                    ],


     
    }),

    computed: {
          ...mapState(['usuario', 'token']),
    },

    watch: {
     
    },

    created () {
      this.initialize()
    },

    methods: {

     ...mapMutations(['mostrarLoading','ocultarLoading']),

      initialize () {
        this.ArrayRalenti = [];
        this.listarPaises (); 
      },



        listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();

            
              
          }).catch(function(error){
            console.log(error);
          });

         


         },


           listar(){

              this.Historial=[];
              this.data_excel=[];

               this.mostrarLoading({titulo:'Accediendo a datos'});
               let config={headers:{token:this.token}};
               let me=this;
              axios.post('resumenes',
                     {   
                      'desde':this.fecha_inicio,
                      'hasta':this.fecha_fin,
                      'id_pais':this.id_pais_activo
                    },
                  config
                  ).then(function(response){
                      console.log(response);
                   
                      
                      me.ArrayRalenti=response.data;
                      me.dialogInicio=false;

                      let sin_vehiculo=[];


                       me.ArrayRalenti.map(function(x){

                        if(x.id_vehiculo){

                         me.data_excel.push({ 
                              'Vehiculo':x.id_vehiculo.nombre,
                              'Placa':x.id_vehiculo.placa,
                              'Flota':x.id_vehiculo.flota,
                              'Distancia':x.distancia,
                              'Ralenti':x.tiempo_parado_enc,
                              'Fecha':me.ConvertirFecha(x.fecha)
                             
                              });  

                              }else{
                                sin_vehiculo.push(x);
                              }



                       });


                       sin_vehiculo.map(function(y){
                          let borrarIndex =  me.ArrayRalenti.indexOf(y);
                          me.ArrayRalenti.splice(borrarIndex, 1);
                       });
                       

                      me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

        },


         ConvertirFecha(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString();
          return(texto);

      },


    },
  }
</script>